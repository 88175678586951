"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.selectOrderedPopulatedNotifications = exports.selectNotifications = exports.selectPopulatedNotifications = exports.selectPopulatedServicesFeaturesArray = exports.selectPopServiceFeatures = exports.selectPopulatedRecommendationsArray = exports.selectPopulatedSurveyUsersArray = exports.selectPopRechargedOrganizations = exports.selectPopQuoteAnalysisBundles = exports.selectPopulatedUsersArray = exports.makeSelectPopulatedCompanyUsers = exports.makeSelectPopulatedServiceComments = exports.selectExtendedPopRechargeInvoices = exports.selectPopRechargeInvoices = exports.selectPopRechargedCompanyVendors = exports.selectPopRechargeImports = exports.selectRawPopRecharges = exports.selectUnfilteredPopulatedServiceStats = exports.makeSelectServiceReviews = void 0;
var reselect_1 = require("reselect");
var utils_1 = require("./utils");
var makeSelectPopulatedArray = function (selectPopulated) { return ((0, reselect_1.createSelector)(
// FirestoreDataObject<T>,
selectPopulated, function (populatedItems) { return populatedItems && Object.keys(populatedItems).map(function (id) { return (__assign({ id: id }, populatedItems[id])); }); })); };
var makeSelectServiceReviews = function (serviceId) { return ((0, utils_1.makePopulate)("".concat(serviceId, "Reviews"), ['user'])); };
exports.makeSelectServiceReviews = makeSelectServiceReviews;
exports.selectUnfilteredPopulatedServiceStats = ((0, utils_1.makePopulate)('service_stats', ['owner', 'companyTeam', ['companyTeam'], 'service']));
exports.selectRawPopRecharges = (0, utils_1.makePopulate)('recharges', ['rechargedOrganization', 'rechargedOrganization.customer', 'rechVendor']);
exports.selectPopRechargeImports = (0, utils_1.makePopulate)('recharge_imports', ['rechVendor']);
exports.selectPopRechargedCompanyVendors = (0, utils_1.makePopulate)('recharged_company_vendors', ['rechVendor']);
var selectAllPopRechargeInvoices = (0, utils_1.makePopulate)('recharge_invoices', ['rechargedCustomer']);
exports.selectPopRechargeInvoices = (0, reselect_1.createSelector)(selectAllPopRechargeInvoices, function (invoices) { return invoices && invoices.filter(function (invoice) { return !invoice.deletedOn; }); });
exports.selectExtendedPopRechargeInvoices = (0, reselect_1.createSelector)(exports.selectPopRechargeInvoices, function (invoices) { return invoices && invoices
    .map(function (invoice) { return (__assign(__assign({}, invoice), { status: invoice.paymentFailed && !['paid', 'voided'].includes(invoice.status) ? 'failed' : invoice.status })); }); });
var makeSelectPopulatedServiceComments = function (serviceId) { return ((0, utils_1.makePopulate)("".concat(serviceId, "Comments"), ['user'])); };
exports.makeSelectPopulatedServiceComments = makeSelectPopulatedServiceComments;
var makeSelectPopulatedCompanyUsers = function (companyId) { return ((0, utils_1.makePopulate)("".concat(companyId, "Users"), ['companyTeam'])); };
exports.makeSelectPopulatedCompanyUsers = makeSelectPopulatedCompanyUsers;
exports.selectPopulatedUsersArray = (0, utils_1.makePopulate)('public_users', ['companyTeam']);
exports.selectPopQuoteAnalysisBundles = (0, utils_1.makePopulate)('quote_analysis_bundles', ['service']);
exports.selectPopRechargedOrganizations = (0, utils_1.makePopulate)('recharged_organizations', ['customer']);
exports.selectPopulatedSurveyUsersArray = (0, reselect_1.createSelector)(exports.selectPopulatedUsersArray, (0, utils_1.makeSelectOrdered)('surveyInvites'), function (users, invites) { return users && invites
    && users.filter(function (u) { return u.isUnauthUser; })
        .map(function (u) { return (__assign(__assign({}, u), { invitation: invites === null || invites === void 0 ? void 0 : invites.find(function (inv) { return inv.usedBy === u.id; }) })); }); });
exports.selectPopulatedRecommendationsArray = (0, utils_1.makePopulate)('recommendations', ['defaultAssignee', 'assignee', 'serviceStat', 'service']);
exports.selectPopServiceFeatures = (0, reselect_1.createSelector)(utils_1.selectFirestore, (0, utils_1.newPopulate)('services_features', ['feature']));
exports.selectPopulatedServicesFeaturesArray = (makeSelectPopulatedArray(exports.selectPopServiceFeatures));
exports.selectPopulatedNotifications = (0, reselect_1.createSelector)(utils_1.selectFirestore, (0, utils_1.newPopulate)('notifications', ['recommendation', 'serviceStat', 'service', 'sourceUser', 'conn']));
exports.selectNotifications = (0, utils_1.makeSelectOrdered)('notifications');
exports.selectOrderedPopulatedNotifications = (0, reselect_1.createSelector)(exports.selectNotifications, exports.selectPopulatedNotifications, function (orderedNotifs, populatedNotifs) { return orderedNotifs && orderedNotifs.map(function (_a) {
    var id = _a.id;
    return (__assign({ id: id }, populatedNotifs[id]));
}); });
